/**
 * Component to render the core Spring button.
 */
import styled from "@emotion/styled";
import Link from "next/link";
import { CSSProperties, ReactNode } from "react";
import Arrow from "../images/Other/SVG/Arrow.svg";
import { MAGENTA, PURPLE, WHITE } from "../utils/colors";
import { MD } from "./constants";
import Strut from "./Strut";

const Container = styled.div`
  @media (max-width: ${MD}px) {
    width: 100%;
  }
  width: 295px;
  height: 90px;
  background-color: ${MAGENTA};
  position: relative;
  cursor: pointer;
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background: linear-gradient(
    115deg,
    ${PURPLE} 0%,
    ${PURPLE},
    60%,
    ${MAGENTA} 100%
  );
  opacity: 0;

  transition: opacity 0.2s ease;
  :hover {
    opacity: 1;
  }
`;

const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const ArrowContainer = styled.span`
  width: 22px;
  height: 18px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TextContainer = styled.span`
  height: 21px;

  font-size: 18px;
  text-transform: uppercase;
  color: ${WHITE};
  letter-spacing: 1.33px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export default function Button({
  children,
  to,
  style,
}: {
  children: ReactNode;
  to: string;
  style?: CSSProperties;
}) {
  return (
    <Link href={to}>
      <a>
        <Container style={style}>
          <Gradient />
          <ContentContainer>
            <TextContainer>{children}</TextContainer>
            <Strut size={8} />
            <ArrowContainer>
              <Arrow />
            </ArrowContainer>
          </ContentContainer>
        </Container>
      </a>
    </Link>
  );
}
