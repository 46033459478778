/**
 * Component to render the press articles section.
 */

import styled from "@emotion/styled";
import Link from "next/link";

import {
  FullPageContent,
  LeftHalfPageContent,
  LeftHalfPageContentNoGutter,
  ResponsiveSpacing,
  RightHalfPageContent,
  RightHalfPageContentNoGutter,
} from "../components/layout";
import Image from "../components/Image";

import businessInsiderImage from "../images/Press/PNG/Business_Insider.png";
import WallStreetJournal from "../images/Press/SVG/Wall_Street_Journal.svg";
import Arrow from "../images/Other/SVG/Arrow.svg";
import { LG, MAX_FULL_SCREEN_WIDTH, MD, SM } from "../components/constants";
import { CORAL, GREY, PURPLE, WHITE, YELLOW } from "../utils/colors";

const ResponsiveRule = styled.div`
  @media (min-width: ${MD}px) {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    border-top: unset;
  }

  border-right: unset;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
`;

const ArrowContainer = styled.span`
  width: 28px;
  height: 23px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const H3Mobile = styled.span`
  color: ${WHITE};

  font-size: 16px;
  line-height: 23px;

  @media (min-width: ${SM}px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export default function PressSection() {
  return (
    <div>
      <div
        className={"flex flex-wrap flex-row"}
        id={"news"}
        style={{ backgroundColor: PURPLE }}
      >
        <div className={"w-full md:w-6/12 flex flex-col justify-center"}>
          <Link
            href={
              "https://www.wsj.com/articles/spring-discovery-brings-machine-learning-to-longevity-sector-1519648200"
            }
          >
            <a
              style={{ color: WHITE }}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <ResponsiveRule>
                <LeftHalfPageContentNoGutter>
                  <ResponsiveSpacing
                    md={{ paddingTop: 38, paddingBottom: 38 }}
                    xs={{ paddingTop: 22, paddingBottom: 22 }}
                  >
                    <div className="flex flex-row items-center justify-around">
                      <div
                        style={{ width: 90, height: 90, color: WHITE }}
                        className="hidden lg:block"
                      >
                        <WallStreetJournal />
                      </div>
                      <H3Mobile style={{ maxWidth: "55%" }}>
                        Spring Brings Machine Learning to Drug Discovery
                      </H3Mobile>
                      <div className="flex flex-col justify-end items-end">
                        <div
                          style={{ width: 52, height: 52, color: WHITE }}
                          className="block lg:hidden"
                        >
                          <WallStreetJournal />
                        </div>
                        <ArrowContainer>
                          <Arrow />
                        </ArrowContainer>
                      </div>
                    </div>
                  </ResponsiveSpacing>
                </LeftHalfPageContentNoGutter>
              </ResponsiveRule>
            </a>
          </Link>
        </div>
        <div className={"w-full md:w-6/12 flex flex-col justify-center"}>
          <Link
            href={
              "https://www.businessinsider.com/ben-kamens-anti-aging-spring-discovery-raises-18-million-2018-12"
            }
          >
            <a
              style={{ color: WHITE }}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <ResponsiveRule>
                <RightHalfPageContentNoGutter>
                  <ResponsiveSpacing
                    md={{ paddingTop: 38, paddingBottom: 38 }}
                    xs={{ paddingTop: 22, paddingBottom: 22 }}
                  >
                    <div className="flex flex-row items-center justify-around">
                      <div
                        style={{ width: 90, height: 90 }}
                        className="hidden lg:flex items-center"
                      >
                        <Image unoptimized src={businessInsiderImage} />
                      </div>
                      <H3Mobile style={{ maxWidth: "55%" }}>
                        Spring Aims to Hasten Scientific Discovery Using Machine
                        Learning
                      </H3Mobile>
                      <div className="flex flex-col justify-end items-end">
                        <div
                          style={{ width: 52, height: 52 }}
                          className="flex lg:hidden items-center"
                        >
                          <Image unoptimized src={businessInsiderImage} />
                        </div>
                        <ArrowContainer>
                          <Arrow />
                        </ArrowContainer>
                      </div>
                    </div>
                  </ResponsiveSpacing>
                </RightHalfPageContentNoGutter>
              </ResponsiveRule>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}
