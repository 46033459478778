/**
 * Component to render the "Trusted By" logos section.
 */

import styled from "@emotion/styled";
import gatesImage from "../images/TrustedBy/PNG/Gates.png";
import gileadImage from "../images/TrustedBy/PNG/Gilead.png";
import broadImage from "../images/TrustedBy/PNG/Broad.png";
import assayWorksImage from "../images/TrustedBy/PNG/AssayWorks.png";
import ucsfImage from "../images/TrustedBy/PNG/UCSF.png";
import { PURPLE, WHITE } from "../utils/colors";
import { LG, MD, SM } from "./constants";
import { ResponsiveSpacing } from "./layout";
import Image from "./Image";
import { Superheader, H2 } from "./typography";

const IconsContainer = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: ${LG}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const TrustedByIcon = styled.span`
  width: 172px;
  height: 86px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function TrustedBySection() {
  return (
    <>
      <div
        className={"w-full flex flex-col items-center"}
        id={"investors"}
        style={{ backgroundColor: PURPLE }}
      >
        <div className={"w-full text-center"} style={{ maxWidth: 980 }}>
          <ResponsiveSpacing
            xs={{ paddingBottom: 8, paddingTop: 14 }}
            lg={{ paddingBottom: 8, paddingTop: 60 }}
          >
            <Superheader>Trusted By Scientists Around The World</Superheader>
          </ResponsiveSpacing>

          <ResponsiveSpacing
            xs={{ paddingBottom: 34 }}
            lg={{ paddingBottom: 34 }}
          >
            <IconsContainer className={"flex-grow"}>
              <TrustedByIcon>
                <Image unoptimized src={gatesImage} />
              </TrustedByIcon>
              <TrustedByIcon>
                <Image unoptimized src={gileadImage} />
              </TrustedByIcon>
              <TrustedByIcon>
                <Image unoptimized src={ucsfImage} />
              </TrustedByIcon>
              <TrustedByIcon>
                <Image unoptimized src={broadImage} />
              </TrustedByIcon>
              <TrustedByIcon>
                <Image unoptimized src={assayWorksImage} />
              </TrustedByIcon>
            </IconsContainer>
          </ResponsiveSpacing>
        </div>
      </div>
    </>
  );
}
