/**
 * Component to render a product screenshots carousel (used on homepage).
 */
import { useState, useEffect, ReactNode } from "react";
import classNames from "classnames";
import Typist from "react-typist";
import "react-typist/dist/Typist.css";
import { css, StyleSheet } from "aphrodite";
import { tw } from "../demos/MegaMap/styles";

import SegmentedControl from "../components/technology/MegaMapEmbed/SegmentedControl";
import {
  LeftHalfPageContent,
  LeftHalfPageContentNoGutter,
  FullPageContent,
  ResponsiveSpacing,
  RightHalfPageContent,
  FullContainer,
} from "../components/layout";
import Image from "../components/Image";
import { PURPLE, GREY } from "../utils/colors";

import ProductImageViewer from "../images/Other/JPG/ProductImageViewer.jpg";
import ProductMeasurementsPageBucketer from "../images/Other/PNG/ProductMeasurementsPageBucketer.png";
import ProductMegamap from "../images/Other/PNG/ProductMegamapNoDevelopment.png";
import ProductTrain from "../images/Other/PNG/Train.png";

type ScreenshotType = "view" | "qc" | "analyze" | "train";

function ProductScreenshots({ view }: { view: ScreenshotType }) {
  let image;
  if (view === "view") {
    image = ProductImageViewer;
  } else if (view === "qc") {
    image = ProductMeasurementsPageBucketer;
  } else if (view === "analyze") {
    image = ProductMegamap;
  } else if (view === "train") {
    image = ProductTrain;
  } else {
    return null;
  }

  return (
    <div
      className={
        "relative overflow-hidden flex items-center justify-center p-6 md:rounded-xl w-full md:w-auto md:max-w-4xl m-0 md:mx-4"
      }
    >
      <div
        className="absolute"
        style={{
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          background: `linear-gradient(
            40deg,
            hsl(253deg 79% 57%) 0%,
            hsl(297deg 98% 40%) 24%,
            hsl(319deg 100% 48%) 35%,
            hsl(329deg 100% 50%) 45%,
            hsl(353deg 100% 65%) 55%,
            hsl(17deg 100% 63%) 65%,
            hsl(33deg 100% 60%) 76%,
            hsl(44deg 100% 61%) 100%
          )`,
        }}
      />
      <div className="flex shadow-2xl z-10">
        <Image
          key={view}
          src={image}
          unoptimized
          layout="intrinsic"
          placeholder="blur"
          className="rounded"
        />
      </div>
    </div>
  );
}

function ProductScreenshotsDescription({ view }: { view: ScreenshotType }) {
  return (
    <div className={css(styles.captionText)}>
      <span className={css(styles.prefixText, tw.whiteSpacePre)}>
        {view === "view"
          ? "Explore and search all images/metadata"
          : view === "qc"
          ? "Detect experimental bias early w/"
          : view === "analyze"
          ? "Beyond traditional analysis w/ AI:"
          : view === "train"
          ? "With AI PhenoSort, scientists"
          : ""}
      </span>{" "}
      <Typist
        className={css(tw.inlineBlock)}
        key={view}
        cursor={{ show: true }}
        avgTypingDelay={50}
      >
        <span className={classNames(css(styles.suffixText))}>
          {view === "view"
            ? "at blazing fast speeds"
            : view === "qc"
            ? "automated QC reporting"
            : view === "analyze"
            ? "multidimensional features, clustering, similarity scores, ..."
            : view === "train"
            ? "visually train their own phenotype models"
            : ""}
        </span>
      </Typist>
    </div>
  );
}

export default function ProductScreenshotsCarousel() {
  const [selected, setSelected] = useState<ScreenshotType>("view");
  const [automaticallySwap, setAutomaticallySwap] = useState<boolean>(true);

  function chooseSelected(newSelected: ScreenshotType) {
    setAutomaticallySwap(false);
    setSelected(newSelected);
  }

  useEffect(() => {
    if (automaticallySwap) {
      const interval = setInterval(() => {
        const allTypes = ["view", "qc", "analyze", "train"];
        const currentIndex = allTypes.indexOf(selected);
        const nextSelected = allTypes[
          (currentIndex + 1) % allTypes.length
        ] as ScreenshotType;

        setSelected(nextSelected);
      }, 7500);

      return () => {
        clearInterval(interval);
      };
    }
  }, [selected, automaticallySwap]);

  return (
    <div className={"w-full h-auto"}>
      <div
        className={
          "mx-auto w-full mb-2 flex flex-col-reverse items-center justify-center"
        }
      >
        <div className="hidden md:block" style={{ color: GREY }}>
          <SegmentedControl
            items={[
              {
                id: "view",
                title: "Explore",
              },
              {
                id: "qc",
                title: "QC",
              },
              {
                id: "analyze",
                title: "Analyze w/ AI",
              },
              {
                id: "train",
                title: "Train models visually",
              },
            ]}
            selected={selected}
            onChange={chooseSelected}
          />
        </div>
        <div className="md:hidden" style={{ color: GREY }}>
          <SegmentedControl
            items={[
              {
                id: "view",
                title: "Explore",
              },
              {
                id: "qc",
                title: "QC",
              },
              {
                id: "analyze",
                title: "Analyze",
              },
              {
                id: "train",
                title: "Train",
              },
            ]}
            selected={selected}
            onChange={chooseSelected}
          />
        </div>
      </div>
      <ResponsiveSpacing xs={{ paddingBottom: 20 }} md={{ paddingBottom: 30 }}>
        <div className="flex justify-center">
          <ProductScreenshots view={selected} />
        </div>
        <div className="hidden md:flex justify-center">
          <ProductScreenshotsDescription view={selected} />
        </div>
      </ResponsiveSpacing>
    </div>
  );
}

const styles = StyleSheet.create({
  captionText: {
    color: GREY,
    fontSize: 16,
    lineHeight: "27px",
    whiteSpace: "nowrap",
  },
  prefixText: {},
  suffixText: {
    textDecoration: "underline",
  },
});
