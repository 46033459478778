import { css, StyleSheet } from "aphrodite";
import { useState } from "react";
import { sum } from "../../../demos/MegaMap/utils/functions";
import { MAGENTA } from "../../../utils/colors";

type Item<T> = {
  id: T;
  title: string;
};

export default function SegmentedControl<T extends string>({
  selected,
  items,
  onChange,
}: {
  selected: T;
  items: Array<Item<T>>;
  onChange: (item: T) => void;
}) {
  const [widths, setWidths] = useState<{ [K: string]: number }>({});

  const selectedIndex = items.findIndex(({ id }) => id === selected);
  const left = sum(items.slice(0, selectedIndex).map(({ id }) => widths[id]));
  const width = widths[selected];

  return (
    <div className={css(styles.track)}>
      <div className={css(styles.container)}>
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            top: 0,
            bottom: 0,
            left,
            width,
            borderRadius: 16.5,
            backgroundColor: MAGENTA,
            transition: "350ms all cubic-bezier(.165, .84, .44, 1)",
          }}
        />
        {items.map((item) => (
          <button
            ref={(ref) => {
              if (ref) {
                const width = ref.getBoundingClientRect().width;
                if (widths[item.id] !== width) {
                  setWidths({
                    ...widths,
                    [item.id]: ref.getBoundingClientRect().width,
                  });
                }
              }
            }}
            key={item.id}
            className={css(
              styles.button,
              ...(selected === item.id ? [styles.active] : [styles.inactive]),
            )}
            onClick={() => onChange(item.id)}
          >
            {item.title}
          </button>
        ))}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  track: {
    position: "relative",
    display: "flex",
    overflow: "auto",
  },
  container: {
    display: "flex",
    position: "relative",
  },
  button: {
    zIndex: 2,
    borderRadius: 0,
    appearance: "none",
    outline: "none",
    border: "none",
    fontSize: 15,
    fontFamily: ["ApercuBoldPro", "system-ui", "Helvetica", "sans-serif"],
    fontWeight: "bold",
    lineHeight: "24px",
    padding: "4px 16px 4px",
    cursor: "pointer",
    whiteSpace: "nowrap",
    transition: "opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1)",
    backgroundColor: "transparent",
  },
  inactive: {
    ":hover": {
      opacity: 0.65,
    },
  },
  active: {
    color: "rgb(255, 255, 255)",
  },
});
