/**
 * Component to render the homepage.
 */

import styled from "@emotion/styled";
import Link from "next/link";
import { MD as BLOG_MD } from "../components/blog-constants";
import Button from "../components/Button";
import ProductScreenshotsCarousel from "../components/ProductScreenshotsCarousel";
import PressSection from "../components/PressSection";
import CardsSection from "../components/CardsSection";
import BlogCard from "../components/CardsSection/BlogCard";
import ScienceCard from "../components/CardsSection/ScienceCard";
import PartnersCard from "../components/CardsSection/PartnersCard";
import TechnologyCard from "../components/CardsSection/TechnologyCard";
import { LG, MAX_FULL_SCREEN_WIDTH, MD, SM } from "../components/constants";

import Footer from "../components/Footer";
import Header from "../components/Header";
import InvestorsSection from "../components/InvestorsSection";
import TrustedBySection from "../components/TrustedBySection";
import {
  FullPageContent,
  LeftHalfPageContent,
  LeftHalfPageContentNoGutter,
  ResponsiveSpacing,
  RightHalfPageContent,
  RightHalfPageContentNoGutter,
} from "../components/layout";
import Page from "../components/Page";
import SEO from "../components/SEO";
import Image from "../components/Image";
import { Body, Caption, H1, H2, Superheader } from "../components/typography";
import BurstSmall from "../images/02_Pattern/SVG/Burst_Small.svg";
import ParallaxPattern from "../images/02_Pattern/SVG/Parallax_Pattern.svg";
import gradient2Image from "../images/03_Color/Gradients/PNG/Spring_Cell_Module_WhiteArtboard_2.png";
import squareOverlay from "../images/04_Photo_Overlays/PNG/Spring_Photo_Overlay_Square.png";
import tissueWideImage from "../images/Other/JPG/Tissue.jpg";
import teamCroppedImage from "../images/Other/PNG/TeamCropped.png";
import Share from "../components/technology/SVG/Share.svg";
import { CORAL, GREY, PURPLE, WHITE, YELLOW } from "../utils/colors";

const Yellow = styled.span`
  color: ${YELLOW};
`;

const TextGradient = styled.div`
  background: linear-gradient(-50deg, ${CORAL} -10%, ${PURPLE} 65%);
`;

const Blend = styled.div`
  background-color: white;
  mix-blend-mode: screen;
`;

const FixedRatio = styled.div`
  position: relative;
  overflow: hidden;

  height: 100vw;
  width: 100vw;

  @media (min-width: ${MD}px) {
    height: 100%;
    width: 100%;
  }
`;

const DynamicHeight = styled(LeftHalfPageContent)`
  @media (min-width: ${MD}px) {
    max-height: ${750 - 90}px;
  }

  @media (min-width: ${LG}px) {
    max-height: ${880 - 90}px;
  }

  @media (min-width: ${MD}px) {
    flex-basis: calc(100vh - 90px);
  }
`;

const FixedHeight = styled(LeftHalfPageContentNoGutter)`
  flex-basis: 90px;
`;

const Flush = styled.div`
  width: 100vw;
  margin-left: -15px;

  @media (min-width: ${SM}px) {
    width: 100vw;
    margin-left: calc((540px - 30px - 100vw) / 2);
  }

  @media (min-width: ${MD}px) {
    margin-left: -20px;
    margin-right: 0;
  }
`;

export default function IndexPage() {
  return (
    <Page>
      <div className="relative">
        <SEO />
        <Header color={PURPLE} />
      </div>

      <div className={"flex flex-wrap flex-row justify-center"}>
        <div className="w-full md:w-6/12 flex flex-col justify-between">
          <DynamicHeight>
            <div
              className="flex flex-col justify-center"
              style={{ minHeight: "100%" }}
            >
              <ResponsiveSpacing
                xs={{ paddingTop: 132 }}
                md={{ paddingTop: 102 }}
              >
                {/* To render the gradient, render the background, then render */}
                {/* the text in black, and mix them with a blend mode of */}
                {/* 'screen'. (We also need an intermediary div to set a white */}
                {/* background evenly over the multi-line text.) */}
                <TextGradient>
                  <Blend>
                    <H1 className={"hidden md:inline"}>
                      Giving
                      <br />
                      scientists
                      <br />
                      superpowers
                    </H1>
                    <H1 className={"inline md:hidden"}>
                      Giving scientists superpowers
                    </H1>
                  </Blend>
                </TextGradient>
              </ResponsiveSpacing>
              <div className={"w-full md:w-10/12"}>
                <ResponsiveSpacing
                  xs={{ paddingTop: 30, paddingBottom: 40 }}
                  md={{ paddingTop: 20, paddingBottom: 30 }}
                  lg={{ paddingTop: 20, paddingBottom: 30 }}
                >
                  <Body style={{ color: GREY }}>
                    AI tools built just for scientists &mdash; making it easy to
                    apply world-leading neural networks to your experiments.
                  </Body>
                </ResponsiveSpacing>
              </div>
            </div>
          </DynamicHeight>
          <FixedHeight>
            <Button to={"/request"}>Request a Demo</Button>
          </FixedHeight>
        </div>
        <div
          className="w-full md:w-6/12 overflow-hidden"
          style={{ backgroundColor: YELLOW }}
        >
          <FixedRatio>
            <Image
              src={gradient2Image}
              layout={"fill"}
              placeholder={"blur"}
              sizes={`(max-width: ${MD}px) 100vw, 50vw`}
            />
            <div
              style={{
                position: "absolute",
                top: "5%",
                bottom: 0,
                right: "-5%",
                left: "-5%",
              }}
            >
              <ParallaxPattern />
            </div>
          </FixedRatio>
        </div>
      </div>

      <TrustedBySection />

      <div
        className={"flex flex-wrap flex-row"}
        style={{ backgroundColor: PURPLE }}
      >
        <div className={"w-full md:w-6/12"}>
          <LeftHalfPageContent
            className={"flex flex-col justify-center"}
            id={"mission"}
          >
            <ResponsiveSpacing
              xs={{ paddingTop: 14, paddingBottom: 34 }}
              md={{ paddingTop: 40, paddingBottom: 102 }}
            >
              <H2>
                Human expertise + <br /> artificial intelligence.
              </H2>
            </ResponsiveSpacing>
          </LeftHalfPageContent>
        </div>
        <div className={"w-full md:w-6/12"}>
          <RightHalfPageContent className={"flex flex-col justify-center"}>
            <ResponsiveSpacing
              xs={{ paddingTop: 14, paddingBottom: 76 }}
              md={{ paddingTop: 40, paddingBottom: 60 }}
            >
              <Body>
                Data size and complexity of scientific experiments is growing
                dramatically, but researchers are still equipped with
                yesterday's software.{" "}
                <Yellow>
                  Spring's products make it easy for scientists to wield the
                  most powerful computational tools in the world{" "}
                </Yellow>
                &mdash; amplifying their impact, speeding up milestones, and
                increasing collaboration between wetlab and drylab.
              </Body>
            </ResponsiveSpacing>
          </RightHalfPageContent>
        </div>
      </div>

      <div className={"flex flex-wrap flex-row overflow-hidden"}>
        <div className="w-full md:w-6/12 relative">
          <LeftHalfPageContentNoGutter>
            <ResponsiveSpacing
              xs={{ paddingTop: 14, paddingBottom: 0 }}
              md={{ paddingTop: 92, paddingBottom: 60 }}
            >
              <ProductScreenshotsCarousel />
            </ResponsiveSpacing>
          </LeftHalfPageContentNoGutter>
        </div>

        <div className={"w-full md:w-6/12"}>
          <RightHalfPageContent>
            <div id={"our-approach"}>
              <div>
                <ResponsiveSpacing
                  xs={{ paddingTop: 14, paddingBottom: 19 }}
                  lg={{ paddingTop: 68, paddingBottom: 21 }}
                >
                  <Superheader style={{ color: GREY }}>
                    Our products
                  </Superheader>
                </ResponsiveSpacing>

                <ResponsiveSpacing
                  xs={{ paddingBottom: 34 }}
                  lg={{ paddingBottom: 40 }}
                >
                  <H2 style={{ color: PURPLE }}>AI for imaging experiments</H2>
                </ResponsiveSpacing>

                <ResponsiveSpacing
                  xs={{ paddingBottom: 34 }}
                  lg={{ paddingBottom: 40 }}
                >
                  <Body style={{ color: GREY }}>
                    Spring's software lets scientists apply the latest neural
                    networks coming out of Google's and others' research labs to
                    their imaging experiments.
                    <br />
                    <br />
                    <Link href={"/technology"}>
                      <a style={{ color: PURPLE }}>
                        Our interface empowers scientists to teach AI
                      </a>
                    </Link>{" "}
                    to identify complex cell behavior within images &mdash;
                    while letting the AI suggest novel cell biology insights for
                    scientists to consider.
                    <br />
                    <br />
                    Like a labmate, our AI assists scientists with analyzing
                    their images and driving towards conclusions about top
                    drugs/targets, mechanism of action, safety, efficacy, and
                    more.
                    <br />
                    <br />
                    <Link href={"/request"}>
                      <a style={{ color: PURPLE }}>
                        Request a demo to see how Spring can immediately add
                        superpowers to your imaging data.
                      </a>
                    </Link>{" "}
                  </Body>
                </ResponsiveSpacing>
              </div>
            </div>
          </RightHalfPageContent>
        </div>
      </div>

      {PressSection()}

      <div className={"flex flex-wrap flex-row overflow-hidden"}>
        <div className="w-full md:w-6/12 relative">
          <LeftHalfPageContentNoGutter>
            <ResponsiveSpacing md={{ paddingTop: 72, paddingBottom: 60 }}>
              <div className="flex justify-center">
                <div
                  className={
                    "relative overflow-hidden flex items-center justify-center p-6 md:rounded-xl w-full md:w-auto md:max-w-4xl m-0 md:mx-4"
                  }
                >
                  <div
                    className="absolute"
                    style={{
                      left: 0,
                      top: 0,
                      right: 0,
                      bottom: 0,
                      background: `linear-gradient(
                        40deg,
                        hsl(253deg 79% 57%) 0%,
                        hsl(297deg 98% 40%) 24%,
                        hsl(319deg 100% 48%) 35%,
                        hsl(329deg 100% 50%) 45%,
                        hsl(353deg 100% 65%) 55%,
                        hsl(17deg 100% 63%) 65%,
                        hsl(33deg 100% 60%) 76%,
                        hsl(44deg 100% 61%) 100%
                      )`,
                    }}
                  />
                  <div className="flex shadow-2xl z-10">
                    <Image
                      src={teamCroppedImage}
                      layout="intrinsic"
                      unoptimized
                      placeholder="blur"
                      className="rounded"
                    />
                  </div>
                </div>
              </div>
            </ResponsiveSpacing>
          </LeftHalfPageContentNoGutter>
        </div>

        <div className={"w-full md:w-6/12"}>
          <RightHalfPageContent>
            <div id={"our-approach"}>
              <div>
                <ResponsiveSpacing
                  xs={{ paddingTop: 40, paddingBottom: 19 }}
                  lg={{ paddingTop: 68, paddingBottom: 21 }}
                >
                  <Superheader style={{ color: GREY }}>
                    Our partnerships
                  </Superheader>
                </ResponsiveSpacing>

                <ResponsiveSpacing
                  xs={{ paddingBottom: 34 }}
                  lg={{ paddingBottom: 40 }}
                >
                  <H2 style={{ color: PURPLE }}>
                    A collaborative AI+bio
                    <br />
                    extension for your team
                  </H2>
                </ResponsiveSpacing>

                <ResponsiveSpacing
                  xs={{ paddingBottom: 34 }}
                  lg={{ paddingBottom: 40 }}
                >
                  <Body style={{ color: GREY }}>
                    Spring's dedicated group of biologists and machine learning
                    experts dramatically accelerate our partners' discovery and
                    development efforts.
                    <br />
                    <br />
                    We put world-leading AI tools in your hands, help you use
                    them, and generate high-dimensional, made-for-ML datasets
                    for your experiments with our automated wetlab.
                    <br />
                    <br />
                    Case study on discovery of innate immune agonists:{" "}
                    <Link href={"https://www.youtube.com/watch?v=7LN4mTILeXA"}>
                      <a target={"_blank"} style={{ color: PURPLE }}>
                        <Share
                          className={"inline-block"}
                          height={12}
                          width={12}
                          style={{ marginRight: 2, marginBottom: 2 }}
                        />
                        video
                      </a>
                    </Link>{" "}
                    and{" "}
                    <Link
                      href={"/pdf/Spring-Inflammasome-Summit-2022-Talk.pdf"}
                    >
                      <a target={"_blank"} style={{ color: PURPLE }}>
                        <Share
                          className={"inline-block"}
                          height={12}
                          width={12}
                          style={{ marginRight: 2, marginBottom: 2 }}
                        />
                        slides
                      </a>
                    </Link>{" "}
                    <br />
                    <br />
                    <Link href={"/request?p=1"}>
                      <a style={{ color: PURPLE }}>
                        Contact us to see how we help partners
                      </a>
                    </Link>{" "}
                    resolve pathways and MOAs, identify targets, discover drugs,
                    and give AI powers to scientists.
                  </Body>
                </ResponsiveSpacing>
              </div>
            </div>
          </RightHalfPageContent>
        </div>
      </div>

      <InvestorsSection />

      <div
        style={{
          backgroundColor: PURPLE,
          borderTop: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <FullPageContent>
          <CardsSection>
            <TechnologyCard />
            <BlogCard />
            <ScienceCard />
          </CardsSection>
        </FullPageContent>
      </div>

      <Footer animate invert={false} />
    </Page>
  );
}
